.learnMore {
  background-color: #fafbfc;
  text-align: center;
  color: #2072e4;
  font-size: 20px;
  padding: 0 8px;
  max-width: 150px;
  width: 150px;
  text-decoration: none;
  border: 1px solid #2072e4;
  border-radius: 6px;
  transition: font-size 100ms ease-in-out;
}
.navLink {
  color: #fff;
  text-decoration: none !important;
}
.navLink :hover {
  text-decoration: none !important;
}
.learnMore:hover {
  font-size: 21px;
  text-decoration: none !important;
  background-color: #ffffff;
  color: #1269e4 !important;
}
