.ant-layout-footer {
  /* background: #9c9dac !important; */
  background: #d3e0ea !important;
  /* color: #fff !important; */
  color: #1687a7 !important;
  padding: 60px 0 !important;
  text-align: center;
}
.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}
.footer {
  background-color: #2e3042;
  color: silver;
  padding: 10px;
  text-align: center;
  font-size: small;
  min-height: 30vh;
}
.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}
