.intro {
  padding: 40px;
  /* border-right: 1px solid #ddd; */
  margin-top: 20px;
}
.intro p {
  text-align: justify;
  line-height: 2rem;
  color: #555;
}
.scholarship {
  padding: 40px;
}
.scholarship p {
  text-align: justify;
  line-height: 2rem;
}
.scholarship h5 {
  color: #555;
}

.scholarship a {
  text-decoration: none;

  color: #1687a7;
}
.scholarship li :hover {
  background-color: #c1daec;
}
.scholarship li {
  list-style: none;
  border-bottom: 1px dashed #ddd;
}

.faqBlock {
  /* color: rgba(8, 8, 9, 0.667); */
  background-color: #6b7587;
  margin: 0;
  padding: 40px 40px;
}
.faqBlock h1 {
  color: #7a8293;
  font-size: 5rem;
}
.faqBlock h4 {
  color: #ffffff;
  font-size: large;
  border-bottom: 1px solid #fff;
  /* background-image: linear-gradient(to right, #c7d3ea, #ffffff, #c7d3ea); */
  height: auto;
  padding: 15px;
}
.faqBlock .answer {
  line-height: 42px;
  margin: auto;
  padding: 10px;
  max-width: 60rem;
  color: rgb(245, 240, 240);
  text-align: justify;
  font-weight: lighter;
}

.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}
.titleHolder h2 {
  color: #1687a7;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}
.quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}
.quickSupport h3 {
  color: #444444;
}
.quickSupport button {
  border: none;
  padding: 0 10px;
  cursor: default;
}
