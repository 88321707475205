/* admin NavBar styles */

@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

/* ------------------------- */

html {
	overflow-y: auto;
}

.active {
	color: #0c6278 !important;
	background-color: #fff;
}

.rounded-left-side {
	border-radius: 20px 0 0 20px;
}

.admin-main-content {
	height: calc(100vh - 50px);
	overflow-y: auto;
	padding-top: 15px;
	padding-left: 15px;
}

.admin-nav {
	background-color: #134f61;
	overflow-x: hidden;
}

.admin-nav-bar {
	height: 50px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #2e3042;
}

.admin-navbar-toggler {
	border: none;
}

.admin-navbar-ul {
	font-size: larger;
	width: 100vw;
	padding-left: 10px;
}

.admin-side-bar {
	overflow-y: hidden;
	background-color: #627291;
}

.admin-side-bar-hide {
	width: 500px !important;
}

.admin-side-bar-show {
	width: 400px !important;
}

.admin-side-bar-holder {
	background-color: #627291;
	/* border-top: 1px solid #d8d6d6; */
	padding-top: 0px;
}

@media (max-width: 991px) {
	.active {
		padding-left: 5px !important;
	}
	.admin-side-bar {
		overflow-y: hidden;
		overflow-x: hidden;
		height: auto;
		background-color: transparent;
	}
	.admin-side-bar-holder {
		margin-top: 0px !important;
		background-color: #046582;
	}
	.admin-navbar-ul {
		padding-left: 10px;
	}
	.admin-nav-bar {
		display: none;
	}
}

/* -------------------- */

/* admin NavBar styles ends here */

.back-btn {
	color: orangered !important;
	text-decoration: none !important;
}

.nav-bar {
	background-color: #fff !important;
	font-size: large;
	top: 0;
	border-bottom: 1px solid #e2e2e2;
	z-index: 1;
}

.nav-links {
	color: #0eb7e5 !important;
}

.nav-links:hover {
	color: #085368 !important;
	text-decoration: none;
	/* border-bottom: 3px solid #cae6ee; */
}

.active-nav-links {
	color: #085368 !important;
	/* border-bottom: 3px solid #1687a7; */
}

.lastPara {
	padding: 20px 0 0 0;
}

.lastPara h3 {
	font-size: large;
	color: white;
}

.lastPara p {
	color: #eef6f6;
	font-size: 16px;
	line-height: 2.5rem;
	padding: 20px 0 0 0;
	text-align: justify;
	font-weight: lighter;
}

.paraContainer {
	color: teal !important;
	font-size: 20px;
	line-height: 3rem;
	font-weight: lighter;
	text-align: center;
	padding: 130px 20px 0 20px;
}

/* icons */
.anticon {
	vertical-align: middle;
}

/* scrollbar styles */
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e2e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #055e7a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #02475c;
}

/* Disabled Cursor */
:disabled {
	cursor: not-allowed;
}

/* Extras */
.titleBox h2 {
	font-size: 28px;
	color: #1687a7;
	text-transform: capitalize;
	font-weight: lighter;
}

.titleBox h3 {
	font-size: 20px;
	color: #1687a7;
	text-transform: uppercase;
	font-weight: lighter;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.transparent-bg {
	background: transparent !important;
	border: none !important;
	color: white !important;
}
.transparent-bg:hover {
	font-weight: bold;
}
