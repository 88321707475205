.app-toast {
    position: fixed;
    left: 50%;
    bottom: 10px;
    user-select: none;
    z-index: 2147483640;
    transform: translate(-50%, 0);
}

.toast-body {
    color: #fff;
}