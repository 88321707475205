.blockquote {
  color: #1687a7;
  font-family: Tahoma, sans-serif;
  font-size: 30px;
  width: 60%;
  margin: 0 auto;
  padding-top: 10rem;
}
.blockquote h1 {
  font-size: 4rem;
}
.blockquote p {
  font-style: italic;
  margin-bottom: 0;
}

.blockquote p::before,
.blockquote p::after {
  content: "“";
  font-family: Georgia;
  font-size: 8rem;
  margin: -2rem 0 0 -4rem;
  position: absolute;
  opacity: 0.5;
}

.blockquote p::after {
  content: "”";
  margin: -1rem -4rem 0 0;
}

.blockquote cite {
  font-size: 1.5rem;
}

@media (max-width: 992px) {
  .blockquote cite {
    font-size: 1.5rem;
  }
  .blockquote p::before,
  .blockquote p::after {
    content: "“";
    font-family: Georgia;
    font-size: 5rem;
    margin: -2rem 0 0 -4rem;
    position: absolute;
    opacity: 0.5;
  }

  .blockquote p::after {
    content: "”";
    margin: -1.5rem 0 0 1rem;
  }
}
