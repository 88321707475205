:root {
	/* colors */
	--primary-100: #d3e7ed;
	--primary-200: #a7cfdc;
	--primary-300: #7ab7ca;
	--primary-400: #4e9fb9;
	--primary-500: #2287a7;
	--primary-600: #1b6c86;
	--primary-700: #145164;
	--primary-800: #0e3643;
	--primary-900: #071b21;

	/* grey */
	--grey-50: #f8fafc;
	--grey-100: #f1f5f9;
	--grey-200: #e2e8f0;
	--grey-300: #cbd5e1;
	--grey-400: #94a3b8;
	--grey-500: #64748b;
	--grey-600: #475569;
	--grey-700: #334155;
	--grey-800: #1e293b;
	--grey-900: #0f172a;

	/* rest of the colors */
	--black: #222;
	--white: #fff;
	--red-light: #f8d7da;
	--red-dark: #842029;
	--green-light: #d1e7dd;
	--green-dark: #0f5132;

	/* rest of the vars */
	--borderRadius: 0.25rem;
	--letterSpacing: 1px;
	--transtion: 0.3s ease-in-out all;

	/* box shadow*/
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* flexbox */
.flex-div {
	display: flex;
	justify-content: space-between;
}
.flex-end-div {
	display: flex;
	justify-content: flex-end;
}

/* login page */
.main-div {
	display: flex;
	height: 80vh;
	margin-top: 20vh;
	justify-content: center;
	align-items: center;
}
.container-div {
	margin: auto;
	margin-top: 5rem;
	width: min(90%, 40rem);
	text-align: center;
	background-color: var(--white);
	box-shadow: var(--shadow-3);
	padding: 2rem;
	border-radius: var(--borderRadius);
}

/* title */
.title {
	margin-top: 1rem;
	text-align: center;
	font-size: 3rem;
}
.title-underline {
	background: var(--primary-500);
	width: 7rem;
	height: 0.25rem;
	margin: 0 auto;
	margin-top: -0.5rem;
	margin-bottom: 3rem;
}

/* form */
.form-input,
.form-textarea {
	width: 100%;
	padding: 0.375rem 0.75rem;
	background: var(--primary-100);
	border: 1px solid var(--grey-500);
	/* border-bottom: ; */
	margin-bottom: 1rem;
}
.form-textarea {
	height: 7rem;
}
::placeholder {
	font-family: inherit;
	font-weight: 600;
	color: var(--grey-600);
}
.form-input:focus,
.form-textarea:focus {
	outline: none;
	border-bottom: 2px solid var(--primary-800);
}

/* buttons */
.btn {
	cursor: pointer;
	color: var(--white);
	background: var(--primary-500);
	font-size: 1rem;
	border: transparent;
	border-radius: var(--borderRadius);
	letter-spacing: var(--letterSpacing);
	padding: 0.375rem 0.75rem;
	box-shadow: var(--shadow-1);
	transition: var(--transtion);
	text-transform: capitalize;
	display: inline-block;
}
.btn:hover {
	background: var(--primary-700);
	box-shadow: var(--shadow-3);
	color: var(--white);
	font-weight: bolder;
}
.btn-block {
	width: 100%;
}

/* loading screen */
@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.loading {
	width: 6rem;
	height: 6rem;
	margin: 20vh auto;
	border: 5px solid var(--grey-400);
	border-radius: 50%;
	border-top-color: var(--primary-500);
	animation: spinner 0.6s linear infinite;
}
