@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  background: #e2e2e2 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
* {
  font-family: "Roboto", sans-serif;
}

/* admin NavBar styles */

/* ------------------------- */

html {
	overflow-y: auto;
}

.active {
	color: #0c6278 !important;
	background-color: #fff;
}

.rounded-left-side {
	border-radius: 20px 0 0 20px;
}

.admin-main-content {
	height: calc(100vh - 50px);
	overflow-y: auto;
	padding-top: 15px;
	padding-left: 15px;
}

.admin-nav {
	background-color: #134f61;
	overflow-x: hidden;
}

.admin-nav-bar {
	height: 50px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #2e3042;
}

.admin-navbar-toggler {
	border: none;
}

.admin-navbar-ul {
	font-size: larger;
	width: 100vw;
	padding-left: 10px;
}

.admin-side-bar {
	overflow-y: hidden;
	background-color: #627291;
}

.admin-side-bar-hide {
	width: 500px !important;
}

.admin-side-bar-show {
	width: 400px !important;
}

.admin-side-bar-holder {
	background-color: #627291;
	/* border-top: 1px solid #d8d6d6; */
	padding-top: 0px;
}

@media (max-width: 991px) {
	.active {
		padding-left: 5px !important;
	}
	.admin-side-bar {
		overflow-y: hidden;
		overflow-x: hidden;
		height: auto;
		background-color: transparent;
	}
	.admin-side-bar-holder {
		margin-top: 0px !important;
		background-color: #046582;
	}
	.admin-navbar-ul {
		padding-left: 10px;
	}
	.admin-nav-bar {
		display: none;
	}
}

/* -------------------- */

/* admin NavBar styles ends here */

.back-btn {
	color: orangered !important;
	text-decoration: none !important;
}

.nav-bar {
	background-color: #fff !important;
	font-size: large;
	top: 0;
	border-bottom: 1px solid #e2e2e2;
	z-index: 1;
}

.nav-links {
	color: #0eb7e5 !important;
}

.nav-links:hover {
	color: #085368 !important;
	text-decoration: none;
	/* border-bottom: 3px solid #cae6ee; */
}

.active-nav-links {
	color: #085368 !important;
	/* border-bottom: 3px solid #1687a7; */
}

.lastPara {
	padding: 20px 0 0 0;
}

.lastPara h3 {
	font-size: large;
	color: white;
}

.lastPara p {
	color: #eef6f6;
	font-size: 16px;
	line-height: 2.5rem;
	padding: 20px 0 0 0;
	text-align: justify;
	font-weight: lighter;
}

.paraContainer {
	color: teal !important;
	font-size: 20px;
	line-height: 3rem;
	font-weight: lighter;
	text-align: center;
	padding: 130px 20px 0 20px;
}

/* icons */
.anticon {
	vertical-align: middle;
}

/* scrollbar styles */
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e2e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #055e7a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #02475c;
}

/* Disabled Cursor */
:disabled {
	cursor: not-allowed;
}

/* Extras */
.titleBox h2 {
	font-size: 28px;
	color: #1687a7;
	text-transform: capitalize;
	font-weight: lighter;
}

.titleBox h3 {
	font-size: 20px;
	color: #1687a7;
	text-transform: uppercase;
	font-weight: lighter;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.transparent-bg {
	background: transparent !important;
	border: none !important;
	color: white !important;
}
.transparent-bg:hover {
	font-weight: bold;
}

/* 
block
----- 
*/

.about_contentHolder__QtAkS {
  margin: 0 0 50px;
}

.about_icon__1gUUO {
  font-size: 40px;
  color: #1890ff;
  margin: 30px 30px;
}

.about_containerFluid__2BMsy {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 65vw;
  align-self: center;
}
.about_keyPoints__1pXzF {
  color: #1d1d1f;
}

.about_keyPoints__1pXzF p {
  color: #1d1d1f;
  font-size: 16px;
  line-height: 2.5rem;
  padding: 0 80px;
  text-align: justify;
}
.about_lastParaContainer__WMwGJ {
  background-color: #8490a8;
}

.about_lastParaOne__3x0US {
  color: #fff;
  background-color: #627291;
  text-align: justify;
}

/* .lastParaOne .confusions {
  background-color: aliceblue;
  font-family: "Kodchasan", sans-serif;
}
.lastParaOne .heading {
  background-color: aliceblue;
  font-family: "Kodchasan", sans-serif;
} */

.about_links__2a1gR {
  text-decoration: none;
  color: rgb(9, 9, 192);
}
.about_titleHolder__245w- {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.about_titleHolder__245w-:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.about_titleHolder__245w- h2 {
  font-size: 28px;
  margin: 0;
  color: #1687a7;
}

.joinXomidhanForm_titleHolder__2zp94 {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
  max-width: 1200px;
}
.joinXomidhanForm_titleHolder__2zp94:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.joinXomidhanForm_titleHolder__2zp94 h2 {
  font-size: 28px;
  margin: 0;
}

.app-toast {
    position: fixed;
    left: 50%;
    bottom: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 2147483640;
    transform: translate(-50%, 0);
}

.toast-body {
    color: #fff;
}
.quote_blockquote__1j7Lh {
  color: #1687a7;
  font-family: Tahoma, sans-serif;
  font-size: 30px;
  width: 60%;
  margin: 0 auto;
  padding-top: 10rem;
}
.quote_blockquote__1j7Lh h1 {
  font-size: 4rem;
}
.quote_blockquote__1j7Lh p {
  font-style: italic;
  margin-bottom: 0;
}

.quote_blockquote__1j7Lh p::before,
.quote_blockquote__1j7Lh p::after {
  content: "“";
  font-family: Georgia;
  font-size: 8rem;
  margin: -2rem 0 0 -4rem;
  position: absolute;
  opacity: 0.5;
}

.quote_blockquote__1j7Lh p::after {
  content: "”";
  margin: -1rem -4rem 0 0;
}

.quote_blockquote__1j7Lh cite {
  font-size: 1.5rem;
}

@media (max-width: 992px) {
  .quote_blockquote__1j7Lh cite {
    font-size: 1.5rem;
  }
  .quote_blockquote__1j7Lh p::before,
  .quote_blockquote__1j7Lh p::after {
    content: "“";
    font-family: Georgia;
    font-size: 5rem;
    margin: -2rem 0 0 -4rem;
    position: absolute;
    opacity: 0.5;
  }

  .quote_blockquote__1j7Lh p::after {
    content: "”";
    margin: -1.5rem 0 0 1rem;
  }
}

.askQuestion_titleHolder__2MRU0 {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
  max-width: 1200px;
}
.askQuestion_titleHolder__2MRU0:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.askQuestion_titleHolder__2MRU0 h2 {
  font-size: 28px;
  margin: 0;
}

.home_learnMore__2wZaW {
  background-color: #fafbfc;
  text-align: center;
  color: #2072e4;
  font-size: 20px;
  padding: 0 8px;
  max-width: 150px;
  width: 150px;
  text-decoration: none;
  border: 1px solid #2072e4;
  border-radius: 6px;
  transition: font-size 100ms ease-in-out;
}
.home_navLink__26gH- {
  color: #fff;
  text-decoration: none !important;
}
.home_navLink__26gH- :hover {
  text-decoration: none !important;
}
.home_learnMore__2wZaW:hover {
  font-size: 21px;
  text-decoration: none !important;
  background-color: #ffffff;
  color: #1269e4 !important;
}

:root {
	/* colors */
	--primary-100: #d3e7ed;
	--primary-200: #a7cfdc;
	--primary-300: #7ab7ca;
	--primary-400: #4e9fb9;
	--primary-500: #2287a7;
	--primary-600: #1b6c86;
	--primary-700: #145164;
	--primary-800: #0e3643;
	--primary-900: #071b21;

	/* grey */
	--grey-50: #f8fafc;
	--grey-100: #f1f5f9;
	--grey-200: #e2e8f0;
	--grey-300: #cbd5e1;
	--grey-400: #94a3b8;
	--grey-500: #64748b;
	--grey-600: #475569;
	--grey-700: #334155;
	--grey-800: #1e293b;
	--grey-900: #0f172a;

	/* rest of the colors */
	--black: #222;
	--white: #fff;
	--red-light: #f8d7da;
	--red-dark: #842029;
	--green-light: #d1e7dd;
	--green-dark: #0f5132;

	/* rest of the vars */
	--borderRadius: 0.25rem;
	--letterSpacing: 1px;
	--transtion: 0.3s ease-in-out all;

	/* box shadow*/
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* flexbox */
.xomidhanAdmin_flex-div__1p8xM {
	display: flex;
	justify-content: space-between;
}
.xomidhanAdmin_flex-end-div__3BO5q {
	display: flex;
	justify-content: flex-end;
}

/* login page */
.xomidhanAdmin_main-div__3tmRV {
	display: flex;
	height: 80vh;
	margin-top: 20vh;
	justify-content: center;
	align-items: center;
}
.xomidhanAdmin_container-div__6kHqu {
	margin: auto;
	margin-top: 5rem;
	width: min(90%, 40rem);
	text-align: center;
	background-color: #fff;
	background-color: var(--white);
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: var(--shadow-3);
	padding: 2rem;
	border-radius: 0.25rem;
	border-radius: var(--borderRadius);
}

/* title */
.xomidhanAdmin_title__i9tFV {
	margin-top: 1rem;
	text-align: center;
	font-size: 3rem;
}
.xomidhanAdmin_title-underline__T3kh- {
	background: #2287a7;
	background: var(--primary-500);
	width: 7rem;
	height: 0.25rem;
	margin: 0 auto;
	margin-top: -0.5rem;
	margin-bottom: 3rem;
}

/* form */
.xomidhanAdmin_form-input__3rh1T,
.xomidhanAdmin_form-textarea__sq3uG {
	width: 100%;
	padding: 0.375rem 0.75rem;
	background: #d3e7ed;
	background: var(--primary-100);
	border: 1px solid #64748b;
	border: 1px solid var(--grey-500);
	/* border-bottom: ; */
	margin-bottom: 1rem;
}
.xomidhanAdmin_form-textarea__sq3uG {
	height: 7rem;
}
::-webkit-input-placeholder {
	font-family: inherit;
	font-weight: 600;
	color: #475569;
	color: var(--grey-600);
}
:-ms-input-placeholder {
	font-family: inherit;
	font-weight: 600;
	color: #475569;
	color: var(--grey-600);
}
::placeholder {
	font-family: inherit;
	font-weight: 600;
	color: #475569;
	color: var(--grey-600);
}
.xomidhanAdmin_form-input__3rh1T:focus,
.xomidhanAdmin_form-textarea__sq3uG:focus {
	outline: none;
	border-bottom: 2px solid #0e3643;
	border-bottom: 2px solid var(--primary-800);
}

/* buttons */
.xomidhanAdmin_btn__2mrr_ {
	cursor: pointer;
	color: #fff;
	color: var(--white);
	background: #2287a7;
	background: var(--primary-500);
	font-size: 1rem;
	border: transparent;
	border-radius: 0.25rem;
	border-radius: var(--borderRadius);
	letter-spacing: 1px;
	letter-spacing: var(--letterSpacing);
	padding: 0.375rem 0.75rem;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: var(--shadow-1);
	transition: 0.3s ease-in-out all;
	transition: var(--transtion);
	text-transform: capitalize;
	display: inline-block;
}
.xomidhanAdmin_btn__2mrr_:hover {
	background: #145164;
	background: var(--primary-700);
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: var(--shadow-3);
	color: #fff;
	color: var(--white);
	font-weight: bolder;
}
.xomidhanAdmin_btn-block__3yM4L {
	width: 100%;
}

/* loading screen */
@keyframes xomidhanAdmin_spinner__1ZAq9 {
	to {
		transform: rotate(360deg);
	}
}

.xomidhanAdmin_loading__3oRgQ {
	width: 6rem;
	height: 6rem;
	margin: 20vh auto;
	border: 5px solid #94a3b8;
	border: 5px solid var(--grey-400);
	border-radius: 50%;
	border-top-color: #2287a7;
	border-top-color: var(--primary-500);
	animation: xomidhanAdmin_spinner__1ZAq9 0.6s linear infinite;
}

.ant-layout-footer {
  /* background: #9c9dac !important; */
  background: #d3e0ea !important;
  /* color: #fff !important; */
  color: #1687a7 !important;
  padding: 60px 0 !important;
  text-align: center;
}
.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}
.footer {
  background-color: #2e3042;
  color: silver;
  padding: 10px;
  text-align: center;
  font-size: small;
  min-height: 30vh;
}
.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.scholarAssist_intro__1js6q {
  padding: 40px;
  /* border-right: 1px solid #ddd; */
  margin-top: 20px;
}
.scholarAssist_intro__1js6q p {
  text-align: justify;
  line-height: 2rem;
  color: #555;
}
.scholarAssist_scholarship__18Usn {
  padding: 40px;
}
.scholarAssist_scholarship__18Usn p {
  text-align: justify;
  line-height: 2rem;
}
.scholarAssist_scholarship__18Usn h5 {
  color: #555;
}

.scholarAssist_scholarship__18Usn a {
  text-decoration: none;

  color: #1687a7;
}
.scholarAssist_scholarship__18Usn li :hover {
  background-color: #c1daec;
}
.scholarAssist_scholarship__18Usn li {
  list-style: none;
  border-bottom: 1px dashed #ddd;
}

.scholarAssist_faqBlock__VBVxR {
  /* color: rgba(8, 8, 9, 0.667); */
  background-color: #6b7587;
  margin: 0;
  padding: 40px 40px;
}
.scholarAssist_faqBlock__VBVxR h1 {
  color: #7a8293;
  font-size: 5rem;
}
.scholarAssist_faqBlock__VBVxR h4 {
  color: #ffffff;
  font-size: large;
  border-bottom: 1px solid #fff;
  /* background-image: linear-gradient(to right, #c7d3ea, #ffffff, #c7d3ea); */
  height: auto;
  padding: 15px;
}
.scholarAssist_faqBlock__VBVxR .scholarAssist_answer__3NhuB {
  line-height: 42px;
  margin: auto;
  padding: 10px;
  max-width: 60rem;
  color: rgb(245, 240, 240);
  text-align: justify;
  font-weight: lighter;
}

.scholarAssist_titleHolder__32Ll5 {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}
.scholarAssist_titleHolder__32Ll5 h2 {
  color: #1687a7;
}

.scholarAssist_titleHolder__32Ll5:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}
.scholarAssist_quickSupport__-BuIM {
  text-align: center;
  max-width: 740px;
  margin: auto;
}
.scholarAssist_quickSupport__-BuIM h3 {
  color: #444444;
}
.scholarAssist_quickSupport__-BuIM button {
  border: none;
  padding: 0 10px;
  cursor: default;
}

