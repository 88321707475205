/* 
block
----- 
*/

.contentHolder {
  margin: 0 0 50px;
}

.icon {
  font-size: 40px;
  color: #1890ff;
  margin: 30px 30px;
}

.containerFluid {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 65vw;
  align-self: center;
}
.keyPoints {
  color: #1d1d1f;
}

.keyPoints p {
  color: #1d1d1f;
  font-size: 16px;
  line-height: 2.5rem;
  padding: 0 80px;
  text-align: justify;
}
.lastParaContainer {
  background-color: #8490a8;
}

.lastParaOne {
  color: #fff;
  background-color: #627291;
  text-align: justify;
}

/* .lastParaOne .confusions {
  background-color: aliceblue;
  font-family: "Kodchasan", sans-serif;
}
.lastParaOne .heading {
  background-color: aliceblue;
  font-family: "Kodchasan", sans-serif;
} */

.links {
  text-decoration: none;
  color: rgb(9, 9, 192);
}
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #1687a7;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
  color: #1687a7;
}
